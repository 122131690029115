import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectedFile, selectedMultiFiles, toggleFilesManagerModal } from '../../actions/fileManagerActions';
import DragAndDropComponent from './DragAndDropComponent'
import {  uploadFiles } from '../../actions/fileManagerActions';
import { assetsURL } from "../../config/Settings"


export default function FileManagerContent(props) {

    const [filtredData, setFiltredData] = useState([]);

    const filesList = useSelector(state => state.filesList);
    const { files, 
            loading,
            error } = filesList;

    const multiFilesSelected = useSelector(state => state.selectedMultiFiles);  

    const filesManagerToggleModal = useSelector(state => state.toggleFilesManagerModal);

    const dispatch = useDispatch();

    useEffect(() => {

        if(files){
            if(files['files']){
                filesFiltre();
            }
        }
            
    }, [files, props]);

    const selectFile = (elem) =>{
       dispatch( selectedFile( filtredData[elem] ) );
       dispatch( toggleFilesManagerModal(false) );
    }

    const text_truncate = (str, length, ending = null) =>{
        if (length === null) {
            length = 100;
        }
        if (ending === null) {
            ending = '...';
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    }
    
    const handleDrop = (files) => {
        const formData = new FormData();
        for (var x = 0; x < files.length; x++) {
            formData.append("file", files[x]);
        }
        try {
            dispatch(uploadFiles(formData))
        } catch (error) {
            console.log(error);
        }
    }

    const filesFiltre = (e = null) =>{
        
        let data = null;

        if(props.filtre === "trash"){
            data = files['files'].filter(item => item.path && item.path.includes('/trash/'));
        }else if(props.filtre === "images"){
            data = files['files'].filter(item => item.extension && !item.path.includes('/trash/') && ( item.extension.includes('jpg') || item.extension.includes('jpeg') || item.extension.includes('png')  ) );
        }else if(props.filtre === "documents"){
            data = files['files'].filter(item => item.extension && !item.path.includes('/trash/') && ( item.extension.includes('pdf') || item.extension.includes('doc') || item.extension.includes('docx')  ) );
        }else if(props.filtre === "videos"){
            data = files['files'].filter(item => item.extension && !item.path.includes('/trash/') && ( item.extension.includes('mp4') || item.extension.includes('avi') || item.extension.includes('flv') || item.extension.includes('mov')  ) );
        }else if(props.filtre === "audio"){
            data = files['files'].filter(item => item.extension && !item.path.includes('/trash/') && ( item.extension.includes('mp3') || item.extension.includes('wav') ) );
        }else{
            data = files['files'].filter(item => item.path && !item.path.includes('/trash/'));
        }

        if(e){
            let textFiltre = e.target.value;
            data = data.filter(item => item.fileName && item.fileName.toLowerCase().includes(textFiltre.toLowerCase()));
        }

        setFiltredData(data);
    }

    const selectMultiFiles = (event, item) =>{

        let filesSelected = multiFilesSelected;

        filesSelected = filesSelected.files;

        if (event.target.checked) {
            filesSelected.push(item);
        }else{
           let index = filesSelected.findIndex(element => element.path === item.path);
           filesSelected.splice(index, 1);
        }

        dispatch( selectedMultiFiles(filesSelected) );
    }

    return  <React.Fragment>
            {loading ? <div>loading ...</div> :
                error ? <div>{error}</div> :
                
            <div className="content-right">
                {/* <!-- file manager main content start --> */}
                <div className="app-file-area">

                    {/* <!-- App File Header Starts --> */}
                    <div className="app-file-header">
                        <div className="sidebar-toggle show-on-medium-and-down mr-1 ml-1">
                        <i className="material-icons">menu</i>
                        </div>
                        <div className="app-file-header-search">
                        <div className="input-field m-0">
                            <i className="material-icons prefix">search</i>
                            <input type="search" id="email-search" placeholder="Search files" onChange={filesFiltre} />
                        </div>
                        </div>
                    </div>
                    {/* <!-- App File Header Ends --> */}

                    {/* <!-- App File Content Starts --> */}
                    <div className="app-file-content row">
                        <h6 className="font-weight-700 mb-3">All Files</h6>

                        {/* <!-- App File - Files Section Starts --> */}
                        {/* <!-- <label className="app-file-label">Files</label> --> */}
                        <DragAndDropComponent handleDrop={handleDrop}>
                            {filtredData ? filtredData.map(function(item, i){
                                return ( 
                                    <div className="col xl3 l6 m3 s6" key={i} >
                                        <div className="card box-shadow-none mb-1 app-file-info">
                                        <div className="card-content">
                                            <div className="gallery-img-select">
                                                <p className="mb-1">
                                                    <label>
                                                        <input type="checkbox" name="selectAll" 
                                                        checked={ multiFilesSelected?.files.findIndex((itmInner) => itmInner.path === item.path) != -1 }
                                                        onChange={(e)=> selectMultiFiles(e, item)} />
                                                        <span></span>
                                                    </label>
                                                </p>
                                            </div>
                                            <div className="app-file-content-logo grey lighten-4" 
                                                onClick={() => selectFile(i)}
                                                style={{
                                                        height: '100px'
                                                        }}>
                                                            
                                                <div className="fonticon"><i className="material-icons">more_vert</i></div>
                                                {
                                                    item.extension != 'jpg' && item.extension != 'png' && item.extension != 'jpeg' ?
                                                    (
                                                        item.extension === 'pdf' ?
                                                            <img className="recent-file" src="/app-assets/images/icon/pdf.png" height="38" width="30" alt="pdf" />
                                                        : item.extension === 'doc' || item.extension === 'docx' ?
                                                            <img className="recent-file" src="/app-assets/images/icon/doc-image.png" height="38" width="30" alt="doc" />
                                                        : <img className="recent-file" src="/app-assets/images/icon/doc.png" height="38" width="30" alt="???" />
                                                    ) : <img src={assetsURL+item.path} alt="" 
                                                            style={{
                                                                height: '100%',
                                                                margin: 'auto',
                                                                display: 'block'
                                                                }} />
                                                }
                                                
                                            </div>
                                            <div className="app-file-details">
                                            <div className="app-file-name font-weight-700" title={item.fileName}>{text_truncate(item.fileName, 30)}</div>
                                            {!filesManagerToggleModal.toggle ? 
                                                <div className="app-file-size">{item.size}</div>
                                            :null}
                                            <div className="app-file-type">
                                                Type : { item.extension }
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                );
                            }) : null}   
                        </DragAndDropComponent>
                        {/* <!-- App File - Files Section Ends --> */}
                        
                    </div>
                </div>
                {/* <!-- file manager main content end  --> */}
            </div>
        }
        </React.Fragment>
}
